import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AuthenticationService } from '../../providers/authentication.service';
import Swal from 'sweetalert2';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {
	confirmRegistration: FormGroup;
	password_pattern = environment.PASSWORD_PATTERN;
	submitted = false;
	loading = false;
	error:boolean;
	queryParam:any;

	constructor(private router: Router, private route: ActivatedRoute, private formBuilder: FormBuilder, private authenticationService: AuthenticationService) { }

	ngOnInit() {
		this.queryParam = this.route.snapshot.queryParams
		this.confirmRegistration = new FormGroup({
            first_name: new FormControl(this.queryParam ? this.queryParam.c : ''),
            last_name: new FormControl(this.queryParam ? this.queryParam.d : ''),
            contact_email: new FormControl(this.queryParam ? this.queryParam.b : ''),
            password: new FormControl('', [Validators.required, Validators.pattern(this.password_pattern)]),
            cpassword: new FormControl(''),
            qn1: new FormControl('', [Validators.required]),
            qn1a: new FormControl('', [Validators.required]),
            qn2: new FormControl('', [Validators.required]),
            qn2a: new FormControl('', [Validators.required]),
            qn3: new FormControl('', [Validators.required]),
            qn3a: new FormControl('', [Validators.required])
        }, this.pwdMatchValidator);
	}

	pwdMatchValidator(frm: FormGroup) {
	    return frm.get('password').value === frm.get('cpassword').value ? null : {'mismatch': true};
	}

	// convenience getter for easy access to form fields
 	 // get f() { return this.confirmRegistration.controls; }

 	confirmRegistrationSubmit() { 	 	
        this.submitted = true;
        if (this.confirmRegistration.valid) {
        	this.loading = true; 
        	const obj = {
				registratin_code:this.queryParam.a,
				email:this.queryParam.b,
				password: this.confirmRegistration.value.password,
				secret_questions:[
					{
						"question": this.confirmRegistration.value.qn1,
						"answer": this.confirmRegistration.value.qn1a
					},
					{
						"question": this.confirmRegistration.value.qn2,
						"answer": this.confirmRegistration.value.qn2a
					},
					{
						"question": this.confirmRegistration.value.qn3,
						"answer": this.confirmRegistration.value.qn3a
					}
				]
			}

        	this.authenticationService.userRegister(obj).subscribe(data =>{
                this.submitted = false;
                this.loading = false;                
                this.confirmRegistration.reset();
                Swal({
		            title: data.message,            
		            type: 'success',
		            showCancelButton: false,
	            }).then((result) => {
		            if (result.value) {
		                this.router.navigate(['/login']);
		            }
	            })
            }, err=>{
                this.loading = false;
            }) 
        }else{        	
        	return;
        }
    }
}
