import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { AuthenticationService } from '../../providers/authentication.service';
import { ReCaptcha2Component } from 'ngx-captcha';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
    @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;
	registerForm: FormGroup;
	submitted = false;
	error:string;
    loading = false;
    recaptcha_site_key = environment.RECAPTCHA_SITE_KEY;

	constructor(private formBuilder: FormBuilder, private authenticationService: AuthenticationService) { }

	ngOnInit() {
		this.registerForm = this.formBuilder.group({
            organization_name: ['', Validators.required],
            contact_first_name: ['', Validators.required],
            contact_last_name: ['', Validators.required],
            contact_email: ['', [Validators.required, Validators.email]],
            contact_title: [''],
            contact_phone: [''],
            contact_phone_ext: [''],
            recaptcha: ['', Validators.required],
            accept_terms_ind: ['', Validators.required],
        });
	}

	// convenience getter for easy access to form fields
    // get f() { return this.registerForm.controls; }

    register() { 
        this.submitted = true;
        if (this.registerForm.valid && this.registerForm.controls['accept_terms_ind'].value === true) {
            this.loading = true;
            this.authenticationService.register(this.registerForm.value).subscribe(data =>{
                this.loading = false;
                this.registerForm.reset();
                this.submitted = false;
                this.reloadCaptcha();
                Swal({ 
                    title: data.message,                
                    type: 'success'
                }) 
            }, err=>{
                this.loading = false;
                if(err.error.errors && err.error.errors.contact_email)
                    this.error = err.error.errors.contact_email;
                else if(err.error.message){
                    this.error = err.error.message;
                }
            })        	       	
        }else{        	
        	return;
        }
    }

    reloadCaptcha() {
        this.captchaElem.reloadCaptcha();
    }

}
