import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse
} from '@angular/common/http';
import { throwError } from 'rxjs';
import { Observable } from 'rxjs/Rx'
import { environment } from '../environments/environment';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

import { AuthGuardService } from './providers/auth-guard.service';

@Injectable()
export class Interceptor implements HttpInterceptor {
	constructor(private router: Router, private authGuardService: AuthGuardService){}
	intercept(req:HttpRequest<any>, next:HttpHandler):Observable<HttpEvent<any>>{
		const secureReq = req.clone({			
			url: `${environment.API_ENDPOINT}${req.url}`
		});
		return next.handle(secureReq)
		.pipe(
			catchError( (error: HttpErrorResponse) => {
				if(error.status == 401) {
					this.authGuardService.checkLogin(false);
					window.location.href = '/login';
					return throwError(error);
				}else{
					return throwError(error);
				}
			})
		)
	}
}
