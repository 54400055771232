// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	API_ENDPOINT: `${(window as any).baseUrl}/api/`,
	PAGE_LIMIT:  5,
	LOAD_SERIAL_NUMBER_PAGE_LIMIT: 25,
	PRODUCT_PAGE_LIMIT: 25,
	PERSONNEL_PAGE_LIMIT: 20,
	INCIDENT_REPORT_PAGE_LIMIT: 25,
	LOCATION_PAGE_LIMIT: 25,
	UPCOMING_SCHEDULED_PAGE_LIMIT:50,
	PASSWORD_PATTERN: '(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=\\S+$).{8,20}',
	RECAPTCHA_SITE_KEY: (window as any).globalConfig.RECAPTCHA_SITE_KEY,
  RECAPTCHAV3_SITE_KEY:(window as any).globalConfig.RECAPTCHAV3_SITE_KEY,
	SELECT2_SEARCH_DATA_LIMIT: 200,
	SELECT2_ENTER_LIMIT: 3,
};
/*
 * In development mode, for easier debugging, you can ignore zone related error
 * stack frames such as `zone.run`/`zoneDelegate.invokeTask` by importing the
 * below file. Don't forget to comment it out in production mode
 * because it will have a performance impact when errors are thrown
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
