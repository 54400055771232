import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import 'rxjs/add/observable/of';

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {
	constructor(private http: HttpClient) { }

	postUserLogin(value): Observable<any> {
		return this.http.post<any>('login', value).pipe(
			tap( event => '',
				error => {}
			)
		);
    }

    logout(): Observable<any> {
		return this.http.get<any>('logout').pipe(
			tap( event => '',
				error => this.handleError(`Failed to logout !`)
			)
		);
    }

    register(value): Observable<any> {
    	return this.http.post<any>('company-registration', value)
    	.pipe(
    		tap(
    			event => '',
    			error => ''
    		)
    	)
    }

    userRegister(value): Observable<any> {
    	return this.http.post<any>('user-registration', value)
    	.pipe(
    		tap(
    			event => '',
    			error => this.handleError(error.error.message)
    		)
    	)
    }

    handleError(message){
		Swal(`${message}`, '', 'error')
	}

}
