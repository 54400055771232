import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginAuthGuardService implements CanActivate{
	isUser = (window as any).authenticated;
	constructor(public router: Router) {
	}

	canActivate(): boolean {
		if (this.isUser) {
			window.location.href = '/home';
			return false;
		}
		return true;
	}
	
	checkLogin(val){
		return this.isUser = val;
	}
}