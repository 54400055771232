import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PasswordService } from "../../providers/password.service";
import Swal from 'sweetalert2';
import { environment } from '../../../environments/environment';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
	resetForm: FormGroup;
	password_pattern = environment.PASSWORD_PATTERN;
	submitted = false;
	loading = false;
	error:boolean;
	queryParam:any;

	constructor(private router: Router, private route: ActivatedRoute, private passwordService: PasswordService) { }

	ngOnInit() {
		this.queryParam = this.route.snapshot.queryParams;

		this.resetForm = new FormGroup({
            username: new FormControl(this.queryParam ? this.queryParam.a : ''),
            password_reset_code: new FormControl(this.queryParam ? this.queryParam.b : ''),
            new_password: new FormControl('', [Validators.required, Validators.pattern(this.password_pattern)]),
            cpassword: new FormControl('')            
        }, this.pwdMatchValidator);
	}

	pwdMatchValidator(frm: FormGroup) {
	    return frm.get('new_password').value === frm.get('cpassword').value ? null : {'mismatch': true};
	}

	resetPassword() { 	 	
        this.submitted = true;
        if (this.resetForm.valid) {
        	this.loading = true;
        	let data = {
        		username:this.resetForm.value.username,
				password_reset_code:this.resetForm.value.password_reset_code,
				new_password:this.resetForm.value.new_password
        	}
        	this.passwordService.resetPassword(data)
        	.subscribe(data => {
        		this.loading = false;
        		Swal({
                    title: data,
                    type: 'success',
                    showCancelButton: false,
                }).then((result) => {
                    if (result.value) {
                        this.router.navigate(['/login']);
                    }
                })
        	}, error =>{
        		this.error = error.error.message
        		this.loading = false;
        	})
        }
        return;
    }    

}
