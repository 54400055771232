import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './providers/auth-guard.service';
import { LoginAuthGuardService } from './providers/login-auth-guard.service';
import { RegisterComponent } from './components/register/register.component';
import { RegistrationComponent } from './components/registration/registration.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { TermsComponent } from './components/terms/terms.component';

const authRoutes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: './modules/login.module#LoginModule'
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'terms',
    component: TermsComponent
  },
  {
    path: 'user-registration',
    component: RegistrationComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'resetpassword',
    component: ResetPasswordComponent
  },
  {
    path: '**',
    redirectTo: 'login'
  },
  {
    path: 'lazy',
    loadChildren: './modules/main.module#MainModule'
  },
];

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: '',
    loadChildren: './modules/main.module#MainModule'
  },
  {
    path: '**',
    redirectTo: 'home'
  }
];

@NgModule({
  imports: [ RouterModule.forRoot((window as any).authenticated ? appRoutes : authRoutes) ],
  providers: [AuthGuardService, LoginAuthGuardService],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
