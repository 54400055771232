import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { PasswordService } from "../../providers/password.service";
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent{
	submitted = false;
	submittedQuestion = false;
	loading = false;
	error:string;
	showQuestion:boolean = false;
	forgotPasswordForm: FormGroup;
	questionForm: FormGroup;
	constructor(private formBuilder: FormBuilder, private passwordService: PasswordService, private router: Router) {
		this.forgotPasswordForm = this.formBuilder.group({
			username: ['', [Validators.required, Validators.email]]
		});

		this.questionForm = this.formBuilder.group({
			secret_question_id: [''],
			secret_question:[''],
			secret_question_answer: ['', [Validators.required]],
			username: ['']
		})
	}

	forgotPassword() {
		this.submitted = true;
        if (this.forgotPasswordForm.valid) {
        	this.loading = true;
        	this.passwordService.forgotPassword(this.forgotPasswordForm.value)
        	.subscribe(data => {
        		this.loading = false;
        		this.showQuestion = true;
        		this.questionForm.controls['secret_question_id'].setValue(data.secret_question_id);
        		this.questionForm.controls['secret_question'].setValue(data.secret_question);
        		this.questionForm.controls['secret_question_answer'].setValue(data.secret_question_answer);
        		this.questionForm.controls['username'].setValue(data.username);

        	}, error =>{
        		this.error = error.error.message
        		this.loading = false;
        		this.showQuestion = false;
        	})
        }
        return;
	}

	onKey(){
		this.error = '';
	}

	validateQuestion(){
		this.submittedQuestion = true;
        if (this.questionForm.valid) {
        	// this.questionForm.value.username = this.forgotPasswordForm.value.username;
        	this.loading = true;
        	this.passwordService.validateSecretQuestion(this.questionForm.value)
        	.subscribe(data => {
        		this.loading = false;
        		Swal({
                    title: data,            
                    type: 'success',
                    showCancelButton: false,
                }).then((result) => {
                    if (result.value) {
                        this.router.navigate(['/login']);
                    }
                })
        	}, error =>{
        		this.error = error.error.message
        		this.loading = false;
        		// this.showQuestion = false;
        	})
        }
        return;

	}
}
