import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectModule } from 'ng2-select';
import { AppRoutingModule } from './app.routing';
import { Interceptor } from './interceptor';
import { NgxCaptchaModule } from 'ngx-captcha';
export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true },
];

import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { TableModule } from 'primeng/table';
import { CalendarModule } from 'primeng/calendar';
import { RegisterComponent } from './components/register/register.component';
import { RegistrationComponent } from './components/registration/registration.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { TermsComponent } from './components/terms/terms.component';

@NgModule({
    declarations: [
        AppComponent,
        RegisterComponent,
        RegistrationComponent,
        PageNotFoundComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        TermsComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        NgxLoadingModule.forRoot({}),
        NgxCaptchaModule
    ],
    providers: [
        httpInterceptorProviders
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }